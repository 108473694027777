import { Component, InputSignal, input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

import { FirstLetterCapitalPipe } from '@shared/pipes/index';

@Component({
  selector: 'item-navigate',
  standalone: true,
  imports: [
    FaIconComponent,
    FirstLetterCapitalPipe
  ],
  template: `
  <div class="flex w-full relative border-none rounded-10 shadow-button" [style.min-height]="height() + 'rem'"
  [class]="cardColor()">
    <span class="min-w-10 h-full rounded-tl-10 rounded-bl-10" [class]="bgColor()"></span>
    <div class="flex w-full items-center mx-20 justify-between py-10 mobile:py-0">
      <div>
        <p class="text-dc-text-primary font-main-bold text-18 leading-20"
        [class.font-main-bold]="!document()"
        [class.font-main-medium]="document()"
        > {{ title() | firstLetterCapital }} </p>
        @if(text()){
          <p class="font-main-regular text-16 leading-18 mt-5">{{ text() }}</p>
        }
      </div>
      <fa-icon class="cursor-pointer grid w-30 aspect-square rounded-10 text-20 leading-25"
      [icon]="[type() == 'check' ? 'far' :'far', icons[type()]]"
      (click)="navigate()"
      [class.text-dc-text-primary]="type() == 'link' || type() == 'view'"
      [class.text-success-check]="type() == 'check'"
      [class.mb-15]="!document()&& !center()"
      [class.self-end]="!document() && !center()"
      [class.place-content-end]="!document()"
      [class.place-content-center]="document()&& !center()"
      />
    </div>
  </div>
  `,
  styles: `
    :host{
      @apply flex w-full;
    }
  `
})
export class ItemNavigateComponent {
  cardColor: InputSignal<string> = input<string>('bg-card');
  bgColor: InputSignal<string> = input<string>('bg-card_border-left');
  title: InputSignal<string> = input<string>('Title');
  text: InputSignal<string> = input<string>('');
  link: InputSignal<string> = input<string>('');
  height: InputSignal<string> = input<string>('5');
  type: InputSignal<string> = input<string>('link');
  document: InputSignal<boolean> = input<boolean>(false);
  center: InputSignal<boolean> = input<boolean>(false);

  icons: any = {
    'link': 'arrow-right',
    'view': 'eye',
    'check': 'circle-check'
  }

  private readonly router = inject(Router);

  navigate(){
    // if(this.link() && (this.type() == 'link' || this.type() == 'view')) {
      this.router.navigate([this.link()] );
    // }
  }

}
